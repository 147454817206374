/* You can add global styles to this file, and also import other style files */
@import "assets/scss/fonts";
@import "assets/scss/variables";
@import "assets/scss/dev.scss";

// Material CSS Reset
html { height: 100%; width: 100%;}
body { margin: 0; font-family: $OpenSans; color: $darkGrey; min-height: 100%; background: $extraLightGrey;}

a {
  :hover {
    text-decoration: none;
  }
}

button:focus {
  outline: none;
}

.text-condensed {
  font-family: $OpenSansCondensed;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

// Nav
.wab-nav {
  background: rgba(0,0,0,0.1);
  .mat-button, .mat-tab-link {
    font-family: $OpenSansCondensed!important;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
  }
  &.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: whitesmoke;
  }

  // TODO: delete when turning to Angular Material 9
  .mat-tab-header-pagination {
    display: none!important;
  }
}

// Tables
.wabel-table-with-actions {
  .mat-row .mat-cell:last-of-type {
    // width: 40px;
  }
}

ul {
  list-style: none;
}

ul.list-disc {
  list-style: disc;
}

// Info Panel
.side-panel {
  h1, h2, h3 {
    font-family: $OpenSansCondensed;
    text-transform: uppercase;
  }

  h1 {
    color: $darkGrey;
    text-align: center;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }

  h2 {
    border-bottom: 1px solid $darkGrey;
  }

  h3 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;
  }

  p {
    font-family: $OpenSans;
    text-align: center;
    font-size: 1rem;
    word-break: break-word;
  }

  .group, .item {
    margin-bottom: 10px;
  }

  //.close-button {
  //  position: absolute;
  //  top: 20px;
  //  left: 20px;
  //  border: none;
  //  background: none;
  //  outline: none;
  //  font-size: 30px;
  //  color: $grey;
  //
  //  &:hover {
  //    color: $darkGrey;
  //  }
  //}
}

:host::ng-deep {
  .qr {
    img {
      width: 50%;
    }
  }
}


// Overriding Bootstrap Breadcrumb
.breadcrumb {
  font-family: $OpenSansCondensed;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  height: 40px;
  display: block;
  text-transform: uppercase;
  @media only screen and (max-width: 1200px) {
    text-align: center;
  }

  li {
    height: 100%;
    line-height: 40px;
    color: $lightGrey;

    .a {
      color: white;

      &:hover {
        color: $lightGrey;
      }
    }
  }
}

.wrapper {
  height: 100%;
  position: relative;
  overflow: auto;
}

#loader {
  opacity: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 99;
  background: $lightGrey;

  .wrapper {
    position: absolute;
    top:50%;
    margin-top:-120px;
    width: 100%;
    vertical-align: middle;
    text-align: center;
    padding-top: 20px;

    .sub-wrapper {
      position: relative;
      width: 200px;
      height: 200px;
      margin: auto;
      border-radius: 50%;
      border: 9px solid white;
      background: white;
      box-shadow: 0 0 23px -6px rgba(0, 0, 0, 0.6);

      .spinner, .background {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 50%;
      }

      .spinner {
        border: 7px solid;
        border-top-color: $red;
        border-right-color: $darkRed;
        border-bottom-color: $blue;
        border-left-color: $darkBlue;
        opacity: 0.8;
        box-sizing: border-box;
        animation: spin 2s linear infinite;
        z-index: 10;
      }

      .background {
        animation: glow 0.5s ease-in-out infinite alternate;
        background: white url("assets/images/needl-small-logo-green.png") no-repeat center;
        background-size: 50%;
        z-index: 9;
        filter: grayscale(100%);
      }
    }
  }
}

.page-details {
  h1, h2, h3, h4, h5 {
    font-family: $OpenSansCondensed;
    text-transform: uppercase;

    button {
      font-family: $OpenSans;
    }
  }

  h1 {
    color: $darkGrey;
    text-align: center;
    font-weight: bold;
  }

  h2 {
    border-bottom: 1px solid $darkGrey;
  }

  h3 {
    font-weight: bold;
    font-size: 22px;
  }

  h4 {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }

  h5 {
    font-size: 16px;
  }

  .group {
    margin-bottom: 50px;
  }
}



.round-logo {
  position: relative;
  margin-left: auto;
  border-radius: 50%;
  background-size: cover;
  border: 3px solid white;
  width: 132px;
  max-width: 100%;
  aspect-ratio: 1;
  box-shadow: 4px 5px 15px rgba(0,0,0,0.6);
  display: inline-block;
}

.bootstrap-timepicker-widget.dropdown-menu {
  z-index: 99!important;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes glow {
  0% { opacity: 0.1; }
  100% { opacity: 0.3; }
}

@keyframes bulb {
  0% { border-width: 8px; }
  100% { border-width: 18px; }
}

mat-icon.small {
  width: 15px;
  height: 15px;
  &.material-icons {
    font-size: 15px;
  }
}
