.cursor-pointer {
    cursor: pointer;
}

.custom_scroll {
    &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #6e6e6e;
        outline: 1px solid #444;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
}
wbo-table {
    table {
        td,
        th {
            padding: 5px !important;
        }
    }
}

.table-responsive {
    &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #6e6e6e;
        outline: 1px solid #444;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
}

.availabilities_td{
    width: 260px;
    max-height: 100px;
    overflow: auto;
    display: block;
}
.no-data-msg{
    font-size: 14px;
    color: #828282;
    margin: 5px;
}
.text-orange{
    color:#ff6d00;
}