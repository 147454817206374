@use '@angular/material' as mat;
@import "@angular/material/theming";

// Color palette
$darkGrey : mat.get-color-from-palette(mat.define-palette(mat.$grey-palette), 800);
$grey : mat.get-color-from-palette(mat.define-palette(mat.$grey-palette), 500);
$lightGrey : mat.get-color-from-palette(mat.define-palette(mat.$grey-palette), 100);
$extraLightGrey : mat.get-color-from-palette(mat.define-palette(mat.$grey-palette), 50);

$red : #C03337;
$darkRed : #78181D;
$blue : #1FAEE4;
$darkBlue : #217ABF;

$sales : $red;
$sourcing : $darkRed;
$event : $darkBlue;

//Header settings
$requestLoadingBarHeight: 5px;
$headerInnerHeight : 60px;
$headerBorderBottomHeight : 0;

$headerHeight : $requestLoadingBarHeight + $headerInnerHeight;

// Footer
$footerHeight : 70px;

//Target Info Parameters
$targetInfoBlockHeight : 135px;
$wabelColor : #C54148;
$manufacturerColor : #d12563;
$purchasingColor : #35aadf;
$expertColor : #3fa235;
$federationColor : #aa4a95;

//Statuses Colors
$statusError: #C54148;
$statusWarning: #de9453;
$statusGood: #48a225;
$statusPending: #08c;

//Event Colors
$frozen: #00A1D9;
$chilledDairy: #ed174f;
$hpc: #A4529F;
$grocery: #EE8C22;
$china: #C60001;
$up: #17a979;
$homeCare: #fac311;
$beautyPersonal: #ed0d6c;
$drinks: #470c0e;
$none: #0775ba;

//Mixins
@mixin backgroundLinearGradient($color) {
  background : linear-gradient($color, darken($color, 10%));
}
