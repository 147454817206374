@use '@angular/material' as mat;
@import '@angular/material/theming';
@import "assets/scss/fonts";
@import "assets/scss/variables";
// Plus imports for other components in your app.

$wabel-app-typography: mat.define-typography-config(
  $font-family: $OpenSans
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat.core($wabel-app-typography);

// Define the default theme (same as the example above).
$wabel-app-primary: mat.define-palette(mat.$grey-palette, 800, 200, 500);
$wabel-app-accent:  mat.define-palette(mat.$blue-palette, 500, 100, A100);
$wabel-app-theme:   mat.define-light-theme($wabel-app-primary, $wabel-app-accent);

// Include the default theme styles.
@include mat.all-component-themes($wabel-app-theme);

// Theming Material

// -- Inputs

.mat-datetimepicker-calendar-header {
  background: mat.get-color-from-palette($wabel-app-accent);
}

$lineHeight: 1.25em;
$paddingSide: .75em;
$paddingTop: .3em;

.mat-form-field {
  line-height: $lineHeight;
}

// -- Inputs Oultined
.mat-form-field-appearance-outline {

  &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  &.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    -webkit-transform: translateY(-$lineHeight) scale(0.75);
    transform: translateY(-$lineHeight) scale(0.75);
  }

  .mat-form-field-infix {
    padding: 0 0 $paddingSide 0;

    input, select, textarea {
      line-height: inherit;
    }

    .mat-form-field-label-wrapper {
      top: -$lineHeight;
      padding-top: $lineHeight;
      line-height: $lineHeight;
    }
  }

  .mat-select-value {
    line-height: $lineHeight;
  }

  .mat-select-arrow-wrapper {
     transform: none!important;
   }

  .mat-form-field-prefix, .mat-form-field-suffix {
    top: 0!important;
    .mat-icon-button {
      width: $lineHeight!important;
      height: $lineHeight!important;
    }
  }

  .mat-form-field-flex {
    padding: $paddingTop $paddingSide 0 $paddingSide!important;
    margin-top: 0!important;
    align-items: center;
  }

  .mat-form-field-label {
    top: unset;
    margin-top: unset;
  }
}

.mat-form-field-appearance-fill {
  .mat-select-arrow-wrapper {
    transform: none!important;
  }
}

wbo-select {
  .mat-form-field-appearance-outline {
    &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
    &.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
      -webkit-transform: translateY(-$lineHeight) scale(0.75);
      transform: translateY(-$lineHeight) scale(0.75);
    }
  }
}
.mat-select-panel {
  margin-top: 2.3em;
  margin-left: .25em;
}


// Forms
// -- Paginator

.mat-paginator {
  background: $extraLightGrey;
}

// Table
// -- Rows

tr.mat-row:hover {
  background-color: mat.get-color-from-palette($wabel-app-accent, 50);
}

// -- Selection Checkboxes
mat-checkbox.wbo-table-selection-checkbox {
  vertical-align: text-bottom;
  label {
    margin: 0;
  }
}

// -- Selection Checkboxes
[wboFilters] {
  background-color: mat.get-color-from-palette($wabel-app-primary, 50);
}

// Layout
// -- Scrollbar
wbo-layout {
  position: absolute;
  min-height: 100%;
  @media (min-width: 768px) {
    width: 100%;
  }

  .mat-sidenav-container {
    min-height: 100%;
  }
}
.mat-sidenav-content, body, mat-sidenav {
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #6e6e6e;
    outline: 1px solid #444;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  }
}
// -- Snackabar
.mat-snack-bar-container.alert-success {
  color: #155724;
  background-color: #d4edda;
}
.mat-snack-bar-container.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
}
.mat-snack-bar-container.alert-warning {
  color: #856404;
  background-color: #fff3cd;
}

// Theming Bootstrap
.btn {
  display: inline-flex;
}
